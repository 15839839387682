import { FC, useEffect, useRef, useState } from "react"
import { cx } from "class-variance-authority"
import { Controller, Control } from "react-hook-form"
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react"
import { useTranslation } from "next-i18next"

import { Icon } from "src/assets/Icon"
import tw from "tailwind-styled-components"
import { LabelStyled } from "../../common/form-controll/Input/Input.styles"
import Typography from "../../typography"

const ComboboxWrapper = tw.div<{ $error: boolean; $disabled: boolean; $open: boolean }>`
w-full focus:outline-none focus:border-secondary-100 text-left border border-neutral-40 rounded-lg relative h-[56px] w-full bg-white
${p => p.$disabled && "bg-neutral-20 "}
${p => p.$open && "border-secondary-100"}
${p => p.$error && "!border-error-100"}
`

interface IListboxOption {
  id: number | string
  value: string | number
  label: string | number
}
interface Props {
  label: string
  name: string
  control: Control<any, any>
  options: IListboxOption[] | undefined
  loading: boolean
  required?: boolean
  defaultValue?: number | undefined
}

const ControlledCombobox: FC<Props> = ({ defaultValue, label, name, control, options, loading, required }) => {
  const [query, setQuery] = useState("")
  const [selectedOption, setSelectedoption] = useState<IListboxOption | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (defaultValue) {
      const option = options?.find(option => option.value === defaultValue)
      setSelectedoption(option || null)
    }
  }, [defaultValue])

  const handleButtonClick = () => {
    // Focus the input when button is clicked
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }
  const isDisabled = !options || options.length === 0 || loading

  const filteredOptions =
    query === ""
      ? options
      : options &&
        options.filter(option => {
          return option.label.toString().toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Combobox
            immediate
            value={selectedOption}
            onChange={(option: IListboxOption) => {
              setSelectedoption(option)
              if (option?.value) {
                field.onChange(option.value)
              }
            }}
            onClose={() => setQuery("")}>
            {({ open }) => (
              <>
                <ComboboxWrapper onClick={handleButtonClick} $open={open} $disabled={isDisabled} $error={!!fieldState.error}>
                  <ComboboxInput
                    ref={inputRef}
                    className={"pt-6 pb-2 px-4 font-tbcx-regular text-2sm text-secondary-100"}
                    displayValue={(person: IListboxOption) => person?.label?.toString()}
                    onChange={event => setQuery(event.target.value)}
                  />

                  <LabelStyled
                    $focused={(selectedOption || field.value || field.value === null || open) && !isDisabled}
                    $error={!!fieldState.error}>
                    {label}
                    {required && <span className="text-error-100">*</span>}
                  </LabelStyled>

                  <Icon name="ComboboxChevronDownIcon" className={cx(open ? "-rotate-180" : "rotate-0")} />
                  <ComboboxOptions
                    className={`shadow-card boder-netural-20 absolute top-16 z-40 max-h-[300px] empty:invisible w-full overflow-auto rounded-xl border bg-white p-2.5 focus:outline-none`}>
                    {filteredOptions?.map(person => (
                      <ComboboxOption
                        key={person.id}
                        value={person}
                        className="data-[focus]:bg-blue-100 relative cursor-pointer select-none rounded-lg px-4 py-3 text-left">
                        {person.label}
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>

                  {fieldState.error && (
                    <Typography type="small" className="block pl-4 pt-1 text-left text-error-100">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </ComboboxWrapper>
              </>
            )}
          </Combobox>
        )
      }}
    />
  )
}

export default ControlledCombobox
