import { useMutation } from "@tanstack/react-query"
import { cx } from "class-variance-authority"
import React, { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import Button from "src/views/components/common/Button/Button"
import ControlledField from "src/views/components/common/form-controll/Controlled/ControlledField"
import Typography from "src/views/components/typography"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import ApplyModalContent from "../modal/ApplyModalContent"

import Image from "next/image"
import { getCheckApplicantCredentials, getCheckApplicantCredentialsInterface } from "src/server/queryHooks/useResumeApi"
import { useResumeApplyContext } from "../../../../context/ResumeApplyProvider"
import { postSendOTP } from "../../../../server/queryHooks/useGlobal"
import { useTranslation } from "next-i18next"
import { MAX_FILE_SIZE } from "src/types/schema/resumeApplyShcema"
import { ApplySteps } from "src/context/ResumeApplyProvider.hooks"

type FormData = {
  name: string
  mobile: number | null
  profession: string
  email: string
  cv?: any
  sms?: string | null
}

const ResumeStepGuestForm = () => {
  const { t } = useTranslation("common")
  const [serverErrMsg, setServerErrMsg] = useState<string | undefined>()

  const { vacancy_id, setResumeModalStep } = useResumeApplyContext()
  const [counter, setCounter] = useState(0)
  const [removeClearButton, setRemoveClearButton] = useState(false)

  React.useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
      setServerErrMsg(`${t("RESEND_IS_AVAILABLE")} ${counter} ${t("IN_SECOND")}`)
    } else {
      setServerErrMsg("")
    }
  }, [counter])

  const checkApplicantCredentialsMutation = useMutation({
    mutationFn: (data: getCheckApplicantCredentialsInterface) => getCheckApplicantCredentials(data),
    mutationKey: [QUERY_KEYS.GET_CHECK_APPLICANT_CREDENTIALS],
    // This API will return success on Error
    onSuccess: (data, variables) => {
      //   Means user has not sent duplicate CV request
      if (data.used_credentials.length === 0) {
        postSendOTPMutation.mutate({ phone: Number(variables.mobile) })
      }

      //   Same email on vacancy
      if (data.used_credentials.includes("email")) {
        setError("email", { type: "custom", message: t("EMAIL_IS_USED") })
      }

      //   Same mobile on vacancy
      if (data.used_credentials.includes("mobile")) {
        setError("mobile", { type: "custom", message: t("NUMBER_IS_USED") })
      }
    }
  })

  const postSendOTPMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_SEND_OTP],
    mutationFn: postSendOTP,
    onError: (err, _, context) => {
      if (err instanceof Error) {
        const error: any = err

        if (error?.response?.data?.message?.error_data?.phone[0] === "invalid_format") {
          setError("mobile", { type: "custom", message: t("WRONG_MOBILE_FORMAT") })
        }
        if (error?.response?.data?.message?.error_data?.phone[0] === "timeout") {
          setCounter(error?.response?.data?.message?.error_data?.timeout[0])
          setServerErrMsg(`${t("RESEND_IS_AVAILABLE")} ${error?.response?.data?.message?.error_data?.timeout[0]} ${t("IN_SECOND")}`)
        }
      }
    },
    onSuccess: () => {
      // Move to last step of modal
      setResumeModalStep(ApplySteps.OTP_FORM)
    }
  })

  const onSubmit = (data: FormData) => {
    // get size of cv
    const cvSize = data.cv.size
    // Check if cv is not empty
    if (!data.cv) {
      setError("cv", { type: "custom", message: t("FILE_UPLOAD_MANDATORY") })
      return
    }
    // Check if cv size is less than ${MAX_FILE_SIZE}
    if (cvSize > MAX_FILE_SIZE) {
      setError("cv", { type: "custom", message: t("FILE_TOO_LARGE") })
      return
    }
    // Check if already applied
    const { email, mobile, name, profession } = data
    if (vacancy_id) {
      localStorage.setItem("formData", JSON.stringify({ email, mobile, name, profession }))

      checkApplicantCredentialsMutation.mutate({ email, mobile: `${mobile}`, vacancy_id })
    }
  }

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    trigger,
    reset,
    formState: { errors }
  } = useFormContext<FormData>()

  const onFormClear = () => {
    setValue("name", "")
    setValue("email", "")
    setValue("profession", "")
    setValue("mobile", null)

    localStorage.removeItem("formData")
    setRemoveClearButton(true)
  }

  // Allocate fields with values from localstorage formData
  useEffect(() => {
    const formData = localStorage.getItem("formData")
    if (formData) {
      const { name, email, mobile, profession } = JSON.parse(formData)
      setValue("name", name)
      setValue("email", email)
      setValue("mobile", mobile)
      setValue("profession", profession)
    }
  }, [setValue])

  const cv = watch("cv")
  const fileInputRef = useRef<any>(null)
  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <ApplyModalContent title={t("SEND_RESUME")} subtitle={t("FILL_INFORMATION")} showBackButton>
      <form className="mt-6 flex flex-1 flex-col gap-4 overflow-auto px-0.5 md:px-2" onSubmit={handleSubmit(onSubmit)}>
        <ControlledField control={control} label={t("NAME_SURNAME")} name="name" />
        <ControlledField maxLength={40} control={control} label={t("PROFESSION")} name="profession" />
        <ControlledField control={control} label={t("EMAIL_ADDRESS")} name="email" />
        <ControlledField control={control} type="tel" label={t("MOBILE")} name="mobile" />

        <input
          type="file"
          accept=".pdf,.docx,.doc,.txt"
          hidden
          name="cv"
          id="fileInput"
          ref={fileInputRef}
          onChange={e => {
            const selectedFile = e.target.files && e.target.files[0]

            if (!selectedFile) return

            // 1) Check file size
            if (selectedFile.size > MAX_FILE_SIZE) {
              setValue("cv", selectedFile)
              setError("cv", { type: "custom", message: t("FILE_TOO_LARGE") })
              return
            }

            const fileType = selectedFile?.type // MIME type
            const validExtensions = [
              ".pdf",
              ".docx",
              ".doc",
              ".txt",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/pdf",
              "text/plain"
            ]

            const isValidFileType = validExtensions.some(ext => fileType && fileType.endsWith(ext))

            if (isValidFileType && e.target.files) {
              setValue("cv", e.target.files[0])
              trigger("cv")
            } else {
              setValue("cv", undefined)
              setError("cv", { type: "custom", message: t("WRONG_FORMAT") })
            }
          }}
        />

        <div
          className={cx(
            "flex w-full cursor-pointer items-start gap-3 self-start rounded-md border p-4 ",
            errors.cv ? "border-error-100" : "border-neutral-20",
            cv && "border-primary-100"
          )}
          onClick={openFileInput}>
          <Image src="/images/cv_upload.svg" width={33} height={33} alt="cv upload" />

          <div className="flex flex-col items-start gap-1 text-left truncate">
            <Typography type="button" weight="bold" className={cx(errors.cv ? "text-error-100" : "text-primary-100")}>
              {cv ? (
                <p>
                  {t("SELECTED_FILE")}: {cv.name}
                </p>
              ) : (
                t("RESUME_UPLOAD")
              )}
            </Typography>
            <Typography type="small" className="text-secondary-50">
              pdf, docx, doc
            </Typography>
            <Typography type="small" className="text-secondary-50">
              {t("MAX_FILE_SIZE_WARNING")}
            </Typography>
          </div>
        </div>
        {errors.cv && (
          <Typography type="small" className="-mt-3 pl-4 text-left text-error-100">
            {errors.cv.message}
          </Typography>
        )}
        <div className="mt-auto">
          {!removeClearButton &&
            localStorage.getItem("formData") &&
            !checkApplicantCredentialsMutation.isLoading &&
            !postSendOTPMutation.isLoading && (
              <Button intent="outline_muted" text={t("CLEAR")} className="w-full mb-4 max-w-full" onClick={onFormClear} />
            )}
          <Button
            intent="primary"
            type="submit"
            text={t("SEND")}
            className="w-full max-w-full"
            loading={checkApplicantCredentialsMutation.isLoading || postSendOTPMutation.isLoading}
          />
        </div>

        {serverErrMsg && (
          <Typography className="text-error-100" type="body">
            {serverErrMsg}
          </Typography>
        )}
      </form>
    </ApplyModalContent>
  )
}

export default ResumeStepGuestForm
