import { useMutation } from "@tanstack/react-query"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { postVacancyUserApply, postVacancyGuestApply } from "../../server/queryHooks/useVacancy"

const useApplyMutations = () => {
  const vacancyGuestApplyMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_VACANCY_GUEST_APPLY],
    mutationFn: postVacancyGuestApply
  })
  const vacancyUserApplyMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_VACANCY_USER_APPLY],
    mutationFn: postVacancyUserApply
  })

  return {
    vacancyGuestApplyMutation,
    vacancyUserApplyMutation
  }
}

export default useApplyMutations
