import { cx } from "class-variance-authority"
import Image from "next/image"
import { FC, useEffect, useState } from "react"
import { STATIC_URL } from "src/env"
import useUser from "src/hooks/useUser"
import useGlobal from "src/server/queryHooks/useGlobal"
import usePersonApi from "src/server/queryHooks/usePersonApi"

interface Props {
  has_logo: 1 | 0 | boolean
  logo_version: number
  company_id: number
  className?: string
  type?: string
}
const CompanyLogo: FC<Props> = ({ has_logo, logo_version, company_id, className, type }) => {
  return (
    <img
      src={has_logo ? `${STATIC_URL}/myjobs/company/${company_id}.jpg?v=${logo_version}` : "/defaults/purple.png"}
      alt="companyLogo"
      className={cx(`w-full rounded-full p-1 `, className)}
    />
  )
}

export default CompanyLogo
