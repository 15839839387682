import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { ACCESS_TOKEN_NAME } from "src/env"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import VacancyService from "src/server/services/VacancyService"
import { IMainPageVacancy, IVacanciesCount, IVacancyResponseV2 } from "src/types/vacancyTypes"
import Cookie from "src/utils/configs/Cookie"

const useVacancy = () => {
  const router = useRouter()

  const accessToken = typeof window !== "undefined" ? Cookie.get(ACCESS_TOKEN_NAME) : null

  const oneVacancyQuery = useQuery({
    queryKey: [QUERY_KEYS.VACANCY_GET_BY_ID, Number(router.query.id)],
    queryFn: () => getSingleVacancy(Number(router.query.id)),
    retry: false,
    enabled: !!router.query.id && router.pathname.includes("/vacancy/[id]")
  })
  const similarVacancyQuery = useQuery<IMainPageVacancy[]>({
    queryKey: [QUERY_KEYS.VACANCY_SIMILAR_GET_BY_ID, Number(router.query.id)],
    queryFn: () => getSimilarVacancy(Number(router.query.id)),
    enabled: !!router.query.id && router.pathname.includes("/vacancy/[id]")
  })

  const vacancyCountQuery = useQuery<IVacanciesCount>({
    queryKey: [QUERY_KEYS.PUBLIC_VACANCY_COUNT],
    queryFn: params => getVacancyCount(params),
    enabled: false
  })

  const appliedVacanciesQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_APPLIED_VACANCIES, { page: router.query.page || 1 }],
    queryFn: () => getAppliedVacancies({ page: parseInt(router.query.page as string) || 1 }),
    enabled: router.pathname.includes("/applied")
  })

  const savedVacanciesQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_SAVED_VACANCIES, { page: router.query.page || 1 }],
    queryFn: () => getSavedVacancies({ page: parseInt(router.query.page as string) || 1 }),
    enabled: router.pathname.includes("/saved")
  })

  const getFilterQuery = useQuery({
    queryKey: [QUERY_KEYS.VACANCY_GET_FILTER],
    queryFn: getFilters,
    enabled: router.pathname.includes("/vacancies") && accessToken
  })

  return {
    vacancyCountQuery,
    appliedVacanciesQuery,
    savedVacanciesQuery,
    getFilterQuery,
    oneVacancyQuery,
    similarVacancyQuery
  }
}

export const getLangsSkillsCountries = async () => {
  try {
    const response: any = await VacancyService.getDataForVacancies()

    return response.data
  } catch (e: any) {
    console.error("getLangsSkillsCountries search api error")
    throw e // Rethrow the error
  }
}

export const getSavedAppliedSubscribedStatus = async () => {
  try {
    const response: any = await VacancyService.getSavedAppliedSubscribedStatus()

    return response.data
  } catch (e: any) {
    console.error("getSavedAppliedSubscribedStatus search api error")
    throw e // Rethrow the error
  }
}

export const getVacancySearch = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacancySearch(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy search api error", e)
    throw e // Rethrow the error
  }
}

export const getVacanciesSearchV2 = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacanciesSearchV2(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy search api error")
    throw e // Rethrow the error
  }
}

export const getVacancyCount = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacancyCount(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy count api error")
    throw e // Rethrow the error
  }
}

export const increaseViewCount = async (params: any) => {
  try {
    const response: any = await VacancyService.increaseViewCount(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy count api error")
    throw e // Rethrow the error
  }
}

export const postVacancyUserApply = async (body: { vacancy_id: number }) => {
  try {
    const response: any = await VacancyService.postVacancyUserApply(body)

    return response.data
  } catch (e: any) {
    console.error("postVacancyUserApply error", e)
    throw e // Rethrow the error
  }
}

export const getSingleVacancy = async (vacancy_id: number) => {
  try {
    const response: any = await VacancyService.getSingleVacancy(vacancy_id)

    return response.data.data
  } catch (e: any) {
    return null
    console.error("getSingleVacancy", e)
  }
}

export const getSimilarVacancy = async (vacancy_id: number) => {
  try {
    const response: any = await VacancyService.getSimilarVacancy(vacancy_id)

    return response.data
  } catch (e: any) {
    console.error("getSimilarVacancy", e)
  }
}

export const getFilters = async () => {
  try {
    const response: any = await VacancyService.getFilters()

    return response.data
  } catch (e: any) {
    console.error("getFilters api error")
    throw e
  }
}

export const addFilter = async (filters: any) => {
  try {
    const response: any = await VacancyService.addFilter(filters)

    return response.data
  } catch (e: any) {
    console.error("addFilter api error")
    throw e
  }
}

interface VacanciesApplyBody {
  vacancy_id: string
  name: string
  mobile: string
  email: string
  profession: string
  code: string
  cv: File
}
export const postVacancyGuestApply = async (data: VacanciesApplyBody) => {
  try {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value)
      } else {
        formData.append(key, value.toString())
      }
    })

    const response: any = await VacancyService.postVacancyGuestApply(formData)

    return response.data
  } catch (e: any) {
    console.error("vacancy apply api error")
    throw e // Rethrow the error
  }
}

export const getAppliedVacancies = async (req?: { page?: number }) => {
  try {
    const response: any = await VacancyService.getAppliedVacancies(req)

    return response.data
  } catch (e: any) {
    console.error("vacancy apply api error")
    throw e // Rethrow the error
  }
}

export const getSavedVacancies = async (req?: { page?: number }) => {
  try {
    const response: any = await VacancyService.getSavedVacancies(req)

    return response.data
  } catch (e: any) {
    console.error("getSavedVacancies", e)
    throw e // Rethrow the error
  }
}

export const deleteSavedVacancy = async (id: number) => {
  try {
    const response: any = await VacancyService.deleteSavedVacancy(id)

    return response.data
  } catch (e: any) {
    console.error("deleteSavedVacancyApplications")
    throw e // Rethrow the error
  }
}

export const postSaveVacancy = async (id: number) => {
  try {
    const response: any = await VacancyService.postSaveVacancy(id)

    return response.data
  } catch (e: any) {
    console.error("error SaveVacancyApplications")
    throw e // Rethrow the error
  }
}

export default useVacancy
