import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { LanguagesCountriesResponse } from "src/types/commonTypes"
import { useTranslation } from "react-i18next"
import { IMainPageV3, ICategorySubCategoryMini } from "../../types/commonTypes"
import MainService from "src/server/services/MainService"
import { getLanguagesCountries } from "../fetchers/languageFetchers"

export const useLanguagesCountriesQuery = () => {
  const { i18n } = useTranslation("common")

  return useQuery<LanguagesCountriesResponse>({
    queryKey: [QUERY_KEYS.GET_LANGUAGES_COUNTRIES, i18n.language],
    queryFn: getLanguagesCountries,
    enabled: !!i18n.language,
    cacheTime: Infinity,
    staleTime: Infinity
  })
}

export const useCategoriesSubcategoriesQuery = () => {
  return useQuery<ICategorySubCategoryMini[]>({
    queryKey: [QUERY_KEYS.CATEGORIES_SUBCATEGORIES],
    queryFn: getCategoriesSubcategories,
    staleTime: Infinity,
    cacheTime: Infinity
  })
}
export const getCategoriesSubcategories = async () => {
  try {
    const response: any = await MainService.getCategoriesSubcategories()
    return response.data
  } catch (e: any) {
    console.error("getCategoriesSubcategories api error")
    throw e // Rethrow the error
  }
}
