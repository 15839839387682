// components/Modal.js
import { Dialog, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import { Fragment } from "react"
import { cn } from "src/utils/cn"
import { twMerge } from "tailwind-merge"

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  maxH?: string
  width?: string
  height?: string
  minH?: string
}
const ApplyModalWrapper = ({ isOpen, onClose, children, className }: Props) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 ">
          <div
            className={cn(
              "flex h-full items-center justify-center overflow-hidden  p-4 pb-8 pt-6 text-center",
              "p-0 md:p-4 md:pb-8 mp:pt-6"
            )}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity transition-transform ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition-opacity transition-transform ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel
                className={twMerge(
                  cx(
                    `relative flex max-h-screen overflow-hidden w-full flex-col md:rounded-2xl bg-white p-4 pb-6 md:p-6 md:pb-8 shadow-xl`,
                    className
                  )
                )}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ApplyModalWrapper
