import { z } from "zod"

export const unauthorizedSchema = z.object({
  first_name: z.string().min(1, {
    message: "აუცილებელია"
  }),
  last_name: z.string().min(1, {
    message: "აუცილებელია"
  }),
  email: z.string().email({
    message: "აუცილებელია"
  }),
  profession: z.string().min(1, {
    message: "აუცილებელია"
  }),
  agree_terms: z.boolean().refine(data => data),
  // mobile should be a positive integer number 9 digits log
  mobile: z
    .string({
      message: "აუცილებელია"
    })
    .refine(data => /^\d{9}$|^\d{12}$|^\+\d{12}$/.test(data), {
      message: "არასწორი მობილური ფორმატი"
    }),
  // industry should be a positive integer
  industry_id: z.number({ message: "აუცილებელია" }),
  cv: z.any({ message: "ატვირთე სივი" }),
  cv_id: z.number().nullable().optional()
})

export type UnauthorizedSchema = z.infer<typeof unauthorizedSchema>
