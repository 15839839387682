import { useEffect, useState } from "react"

export enum ApplySteps {
  SIGN_IN_OR_MANUAL_SEND = "SIGN_IN_OR_MANUAL_SEND",
  LOGIN_OR_FINISH_PROFILE = "LOGIN_OR_FINISH_PROFILE",
  GUEST_FORM = "GUEST_FORM",
  OTP_FORM = "OTP_FORM",
  CONFIRMATION = "CONFIRMATION",
  SUCCESS = "SUCCESS"
}

export interface ResumeApplyState {
  vacancy_id: number | null
  resumeModalStep: ApplySteps | null
  prevStep: ApplySteps | null
  isClient: boolean
}
export const useResumeApplyState = () => {
  const [state, setState] = useState<ResumeApplyState>({
    vacancy_id: null,
    resumeModalStep: null,
    prevStep: null,
    isClient: false
  })

  useEffect(() => {
    setState(prev => ({ ...prev, isClient: true }))
  }, [])

  useEffect(() => {
    if (state.vacancy_id) {
      setState(prev => ({ ...prev, resumeModalStep: ApplySteps.SIGN_IN_OR_MANUAL_SEND }))
    }
  }, [state.vacancy_id])

  const setVacancyId = (id: number | null) => {
    setState(prev => ({ ...prev, vacancy_id: id }))
  }

  const setResumeModalStep = (step: ApplySteps | null) => {
    setState(prev => ({ ...prev, prevStep: state.resumeModalStep ?? null }))
    setState(prev => ({ ...prev, resumeModalStep: step }))
  }

  const closeAndResetModal = () => {
    setState(prev => ({
      ...prev,
      vacancy_id: null,
      resumeModalStep: null
    }))
  }

  return {
    state,
    setVacancyId,
    setResumeModalStep,
    closeAndResetModal
  }
}
