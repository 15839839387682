import MainService from "src/server/services/MainService"

export const getLanguagesCountries = async () => {
  try {
    const response: any = await MainService.getLanguagesCountries()

    return response.data
  } catch (e: any) {
    console.error("getLanguagesCountries  api error")
    throw e // Rethrow the error
  }
}
