import HttpService from "./HttpService"

class NaturalPersonService extends HttpService {
  postNaturalPersonVacancyApplicant(data?: any) {
    return this.post("/natural-person/vacancy/applicant", data)
  }

  deleteNaturalPerson() {
    return this.delete("/natural-person")
  }
}

export default new NaturalPersonService()
