import HttpService from "./HttpService"

class VacancyService extends HttpService {
  getVacancySearch(params: any) {
    const queryParams = [
      "job_types",
      "educations",
      "categories",
      "sub_categories",
      "experience_levels",
      "skills",
      "hire_types",
      "countries",
      "employment_types"
    ]
    queryParams.forEach(param => {
      if (params[param] && params[param].length > 0) {
        params[param] = (params[param] + "").split(",")
      }
    })

    return this.get("/public/vacancies", params)
  }
  getVacanciesSearchV2(params: any) {
    const queryParams = [
      "job_types",
      "educations",
      "categories",
      "sub_categories",
      "experience_levels",
      "skills",
      "hire_types",
      "countries",
      "employment_types"
    ]
    queryParams.forEach(param => {
      if (params[param] && params[param].length > 0) {
        params[param] = (params[param] + "").split(",")
      }
    })

    return this.get("/public/vacancies/v2", params)
  }

  getVacancyCount(params: any) {
    return this.get("/public/vacancies/count", params)
  }
  getDataForVacancies() {
    return this.get("/public/vacancies/data-for-vacancies")
  }

  increaseViewCount(id: number) {
    return this.get(`/public/vacancies/${id}/view`)
  }

  postVacancyGuestApply(data: any) {
    return this.post("/public/vacancies/apply", data, {
      "Content-Type": "multipart/form-data"
    })
  }

  postVacancyUserApply(data: { vacancy_id: number }) {
    return this.post("/natural-person/vacancy/applicant", data)
  }

  getAppliedVacancies(req?: { page?: number }) {
    const params = req ? { ...req } : {}
    return this.get("/natural-person/vacancy/applied", params, null)
  }

  getSavedVacancies(req?: { page?: number }) {
    const params = req ? { ...req } : {} // Handle undefined `req`
    return this.get("/natural-person/vacancy/", params, null)
  }

  deleteSavedVacancy(id: number) {
    return this.delete(`/natural-person/vacancy/${id}/remove`)
  }

  postSaveVacancy(id: number) {
    return this.post(`/natural-person/vacancy/${id}/save`)
  }

  getFilters() {
    return this.get(`/natural-person/vacancy/filters`)
  }

  addFilter(filters: any) {
    return this.put("/natural-person/vacancy/filters", filters)
  }

  getSingleVacancy(vacancy_id: number) {
    return this.get("/public/vacancies/" + vacancy_id)
  }
  getSimilarVacancy(vacancy_id: number) {
    return this.get(`/public/vacancies/${vacancy_id}/similar`)
  }

  getSavedAppliedSubscribedStatus() {
    return this.get("/natural-person/vacancy/saved-applied-status")
  }

  getVacanciesCheckApplicantCredentials(data: any) {
    return this.get("/public/vacancies/check-applicant-credentials", data)
  }
}

export default new VacancyService()
