import Image from "next/image"
import Skeleton from "react-loading-skeleton"
import { useResumeApplyContext } from "src/context/ResumeApplyProvider"
import useOriginUrl from "src/hooks/useOriginUrl"
import Button from "src/views/components/common/Button/Button"
import Typography from "src/views/components/typography"
import ApplyModalContent from "../modal/ApplyModalContent"
// Skeleton CSS
import { useTranslation } from "next-i18next"
import { ErrorBoundary } from "react-error-boundary"
import "react-loading-skeleton/dist/skeleton.css"
import { ApplySteps } from "src/context/ResumeApplyProvider.hooks"
import Cookie from "src/utils/configs/Cookie"
import Spinner from "src/views/components/UI/loaders/Spinner"
import { useNaturalPersonQuery } from "../../../../query-library/queries/naturalPersonQueries"
import ErrorFallback from "../../Error/ErrorFallback"
import ApplyFormUnauthorized from "../CreateUnauthorizedUser/apply-unauthorized-form"

// Step 1
const ResumeStepLoginOrApplyForm = () => {
  const { t } = useTranslation("common")
  const { initiateLogin } = useOriginUrl()

  const { setResumeModalStep } = useResumeApplyContext()
  const naturalPersonQuery = useNaturalPersonQuery()

  if (naturalPersonQuery.isFetching) {
    return <Spinner className="flex h-full items-center justify-center" size={24} />
  }

  // If user is NOT authenticated
  if (!Cookie.isAuthenticated) {
    return (
      <ApplyModalContent title={t("SEND_RESUME")} subtitle={t("FOR_SENDING_RESUME")}>
        <div className="flex flex-1 flex-col items-center justify-start ">
          <Image src="/images/logos/tnet200.svg" width={200} height={200} alt="tnet logo" className="mb-[62px] mt-[62px] flex-1" />

          <div className="w-full px-16 ">
            <Button text={t("ENTER")} intent="tertiary" className="w-full" weight={"regular"} onClick={initiateLogin} />
            <div className="flex w-full items-center  gap-2.5 pt-4 ">
              <div className="h-[1px] flex-1 bg-neutral-40"></div>
              <Typography type="body" className="text-secondary-50">
                {t("OR")}
              </Typography>
              <div className="h-[1px] flex-1 bg-neutral-40"></div>
            </div>

            <Button
              text={t("SEND_MANUALLY")}
              intent="text"
              className="mt-3 w-full "
              weight="medium"
              type="button"
              onClick={() => setResumeModalStep(ApplySteps.GUEST_FORM)}
            />
          </div>
        </div>
      </ApplyModalContent>
    )
  }

  if (Cookie.isAuthenticated) {
    // If user IS authenticated and has completed register wizard
    // Return loader and apply to vacancy
    if (naturalPersonQuery.isLoading) {
      return <Skeleton count={22} />
    }

    // If user IS authenticated but has no Profile / not completed register wizard
    // Return unauthorized form
    if (naturalPersonQuery.isError) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ApplyFormUnauthorized />
        </ErrorBoundary>
      )
    }

    // If user IS authenticated but has no Profile / not completed register wizard
    // Return unauthorized form with default values of natural person
    if (naturalPersonQuery.isSuccess) {
      const naturalPerson = naturalPersonQuery.data
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ApplyFormUnauthorized
            defaultValues={{
              first_name: naturalPerson.name,
              last_name: naturalPerson.surname,
              email: naturalPerson.email,
              mobile: naturalPerson.mobile,
              profession: naturalPerson.profession,
              industry_id: Number(naturalPerson?.industry_id),
              cv_id: naturalPerson.cv?.id,
              agree_terms: true
            }}
            cv_file={naturalPerson.cv?.file}
            cv_title={naturalPerson.cv?.title}
            isAuthorized
          />
        </ErrorBoundary>
      )
    }
  }

  // This should never happen but better safe than sorry.
  return null
}

export default ResumeStepLoginOrApplyForm
