import { cx } from "class-variance-authority"
import { FC, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

import Button from "src/views/components/common/Button/Button"
import ControlledField from "src/views/components/common/form-controll/Controlled/ControlledField"
import Typography from "src/views/components/typography"

import Image from "next/image"
import { useTranslation } from "next-i18next"
import { MAX_FILE_SIZE } from "src/types/schema/resumeApplyShcema"
import { useResumeApplyContext } from "src/context/ResumeApplyProvider"

import ApplyModalContent from "../modal/ApplyModalContent"
import IndustrySelect from "./IndustrySelect"
import Checkbox from "../../common/form-controll/Checkbox/Checkbox"
import { unauthorizedSchema, UnauthorizedSchema } from "./schema"
import { ApplySteps } from "src/context/ResumeApplyProvider.hooks"
import { Icon } from "src/assets/Icon"
import ModalWrapper from "src/views/components/Modal/ModalWrapper"
import ProfileModalTitle from "../../../pages/profile/ProfileModalActions/ProfileModalTitle"
import { useRouter } from "next/router"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { STATIC_URL } from "src/env"
import { useUnauthorizedNaturalPersonApplyMutation } from "src/query-library/mutations/naturalPeresonMutations"
import { withMobile } from "src/query-library/fetchers/naturalPersonFetchers"

interface Props {
  defaultValues?: UnauthorizedSchema
  isAuthorized?: boolean
  cv_file?: string
  cv_title?: string
}
const initialDefaultValues = {
  first_name: "",
  last_name: "",
  profession: "",
  email: "",
  mobile: "",
  cv: undefined,
  agree_terms: true,
  industry_id: undefined
}
interface CVSelectModalProps {
  isOpen: boolean
  onClose: () => void
  uploadFile: any
  formValues: any
  vacancy_id: number
}
const CVSelectModalComponent = ({ isOpen, onClose, uploadFile, formValues, vacancy_id }: CVSelectModalProps) => {
  const { t } = useTranslation("common")
  const router = useRouter()
  const handleNavigate = () => {
    router.push({
      pathname: "/cv",
      query: {
        ...formValues,
        vacancy_id
      }
    })
  }
  return (
    <ModalWrapper height={"310px"} width={"549px"} isOpen={isOpen} onClose={onClose} className="pb-0">
      <ProfileModalTitle title={t("ADD_RESUME")} onClose={onClose} />
      <div className="mt-7 flex items-center justify-center gap-4">
        <div
          onClick={uploadFile}
          className={cx(
            "group flex flex-1 cursor-pointer flex-col items-center gap-4"
            // 'rounded-2xl outline-1 outline-neutral-40 hover:outline'
          )}>
          <Icon name="UploadCV" className="transition-all group-hover:fill-primary-100" />
          <span className=" transition-all group-hover:text-primary-100">{t("UPLOAD_RESUME")}</span>
        </div>
        <Typography type="small" color="light">
          {t("OR")}
        </Typography>
        <div
          onClick={handleNavigate}
          className={cx(
            "group flex flex-1 cursor-pointer flex-col items-center gap-4"

            // 'rounded-2xl outline-1 outline-neutral-40 hover:outline'
          )}>
          <Icon name="MagicGenerateCV" className="transition-all group-hover:fill-primary-100" />
          <span className=" transition-all group-hover:text-primary-100">{t("GENERATE_RESUME")}</span>
        </div>
      </div>
    </ModalWrapper>
  )
}

const ApplyUnauthorizedForm: FC<Props> = ({ isAuthorized = false, cv_title, defaultValues = initialDefaultValues, cv_file }) => {
  const { t } = useTranslation("common")
  const { vacancy_id, setResumeModalStep } = useResumeApplyContext()
  const [isOpenCVSelectModal, setIsOpenCVSelectModal] = useState(false)
  const queryClient = useQueryClient()
  // * Mutations
  const applyMutation = useUnauthorizedNaturalPersonApplyMutation()

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    trigger,
    formState: { errors }
  } = useForm<UnauthorizedSchema>({
    resolver: zodResolver(unauthorizedSchema),
    defaultValues
  })

  const onSubmit = (data: UnauthorizedSchema) => {
    // Check if cv is not empty
    if (!data?.cv && !cv_id) {
      setError("cv", { type: "custom", message: t("FILE_UPLOAD_MANDATORY") })
      return
    }

    if (data.cv) {
      delete data.cv_id
      // get size of cv
      const cvSize = data.cv.size

      // Check if cv size is less than 5mb
      if (cvSize > MAX_FILE_SIZE) {
        setError("cv", { type: "custom", message: t("FILE_TOO_LARGE") })
        return
      }
    }

    const mobile = data.mobile
    if (vacancy_id) {
      applyMutation.mutate(
        { ...data, mobile: withMobile(mobile), vacancy_id },
        {
          onSuccess: () => {
            setResumeModalStep(ApplySteps.SUCCESS)
            queryClient.invalidateQueries([QUERY_KEYS.GET_SAVED_APPLIED_STATUS])
          }
        }
      )
    }
  }

  const cv = watch("cv")
  const cv_id = watch("cv_id")
  const fileInputRef = useRef<any>(null)

  const onCvModalOpen = async () => {
    if (cv || cv_id) return

    // validating form before openning
    const isValid = await trigger()
    if (isValid) {
      setIsOpenCVSelectModal(true)
    }
    // if (fileInputRef.current) {
    //   fileInputRef.current.click()
    // }
  }

  const openFileInput = () => {
    if (fileInputRef.current) {
      setIsOpenCVSelectModal(false)
      fileInputRef.current.click()
    }
  }

  const clearCV = (e: any) => {
    e.stopPropagation()
    setValue("cv", undefined)
    setValue("cv_id", undefined)
  }

  const openCv = () => {
    window.open(`${STATIC_URL}/myjobs/registered_cvs/${cv_file}`, "_blank")
  }

  if (!vacancy_id) return <div>Vacancy not Found</div>

  return (
    <ApplyModalContent title={t("SEND_RESUME")} subtitle={t("FILL_INFORMATION")} showBackButton>
      <CVSelectModalComponent
        isOpen={isOpenCVSelectModal}
        onClose={() => setIsOpenCVSelectModal(false)}
        uploadFile={openFileInput}
        formValues={watch()}
        vacancy_id={vacancy_id}
      />
      <form className="mt-6 flex flex-1 flex-col gap-4 overflow-auto md:px-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row gap-4">
          <ControlledField control={control} label={t("NAME")} name="first_name" disabled={!!defaultValues.first_name} />
          <ControlledField control={control} label={t("SURNAME")} name="last_name" disabled={!!defaultValues.last_name} />
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <IndustrySelect defaultValue={defaultValues.industry_id} control={control} />
          <ControlledField maxLength={40} control={control} label={t("PROFESSION")} name="profession" />
        </div>
        <ControlledField control={control} label={t("EMAIL_ADDRESS")} name="email" />
        <ControlledField control={control} type="tel" label={t("MOBILE")} name="mobile" />

        <input
          type="file"
          accept=".pdf,.docx,.doc,.txt"
          hidden
          name="cv"
          id="fileInput"
          ref={fileInputRef}
          onChange={e => {
            const selectedFile = e.target.files && e.target.files[0]

            if (!selectedFile) return

            // 1) Check file size
            if (selectedFile.size > MAX_FILE_SIZE) {
              setValue("cv", selectedFile)
              setError("cv", { type: "custom", message: t("FILE_TOO_LARGE") })
              return
            }

            const fileType = selectedFile?.type // MIME type
            const validExtensions = [
              ".pdf",
              ".docx",
              ".doc",
              ".txt",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/pdf",
              "text/plain"
            ]

            const isValidFileType = validExtensions.some(ext => fileType && fileType.endsWith(ext))

            if (isValidFileType && e.target.files) {
              setValue("cv", e.target.files[0])
              trigger("cv")
            } else {
              setValue("cv", undefined)
              setError("cv", { type: "custom", message: t("WRONG_FORMAT") })
            }
          }}
        />

        <div
          className={cx(
            "flex w-full items-start gap-3 self-start rounded-md border p-4",
            errors.cv ? "border-error-100" : "border-neutral-20",
            !cv && !cv_id && "cursor-pointer"
          )}
          onClick={onCvModalOpen}>
          <Image src="/images/cv_upload.svg" width={33} height={33} alt="cv upload" />

          <div className="flex flex-col items-start gap-1 mr-auto text-left truncate">
            <Typography
              type="button"
              weight="bold"
              className={cx(errors.cv ? "text-error-100" : "text-primary-100", "text-sm md:text-2sm")}>
              {cv || cv_id ? (
                <p className="text-left">
                  {cv ? t("SELECTED_FILE") : t("PROFILE_CV")}: {cv ? cv?.name : cv_title}
                </p>
              ) : (
                t("RESUME_UPLOAD")
              )}
            </Typography>
            <Typography type="small" className="text-secondary-50">
              pdf, docx, doc
            </Typography>
            <Typography type="small" className="text-secondary-50">
              {t("MAX_FILE_SIZE_WARNING")}
            </Typography>
          </div>

          <div className="flex items-center gap-3 md:gap-4">
            {cv_id && (
              <div
                className={cx(
                  "flex ml-auto h-[32px] w-[32px] md:h-[36px] md:w-[36px] cursor-pointer items-center justify-center rounded-full border border-secondary-20 transition-all hover:bg-neutral-10"
                )}
                onClick={openCv}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M9 4.875C11.8425 4.875 14.3775 6.4725 15.615 9C14.3775 11.5275 11.85 13.125 9 13.125C6.15 13.125 3.6225 11.5275 2.385 9C3.6225 6.4725 6.1575 4.875 9 4.875ZM9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375ZM9 7.125C10.035 7.125 10.875 7.965 10.875 9C10.875 10.035 10.035 10.875 9 10.875C7.965 10.875 7.125 10.035 7.125 9C7.125 7.965 7.965 7.125 9 7.125ZM9 5.625C7.14 5.625 5.625 7.14 5.625 9C5.625 10.86 7.14 12.375 9 12.375C10.86 12.375 12.375 10.86 12.375 9C12.375 7.14 10.86 5.625 9 5.625Z"
                    fill="#909090"
                  />
                </svg>
              </div>
            )}

            {(cv || cv_id) && (
              <div
                className={cx(
                  "flex ml-auto h-[32px] w-[32px] md:h-[36px] md:w-[36px] cursor-pointer items-center justify-center rounded-full border border-secondary-20 transition-all hover:bg-neutral-10"
                )}
                onClick={clearCV}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M12 6.75V14.25H6V6.75H12ZM10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3H11.625L10.875 2.25ZM13.5 5.25H4.5V14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25Z"
                    fill="#909090"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        {errors.cv && (
          <Typography type="small" className="-mt-3 pl-4 text-left text-error-100">
            {errors.cv.message}
          </Typography>
        )}

        <Controller
          name="agree_terms"
          control={control}
          defaultValue={true}
          render={({ field }) => (
            <Checkbox className="w-auto self-start pl-1" selected={field.value} label={t("AGREE_DATA_PROCESS")} {...field} />
          )}
        />

        <Button
          intent="primary"
          type="submit"
          text={t("SEND")}
          className="mt-auto w-full max-w-full"
          loading={applyMutation.isLoading}
          disabled={!watch("agree_terms")}
        />

        {/* {serverErrMsg && (
          <Typography className="text-error-100" type="body">
            {serverErrMsg}
          </Typography>
        )} */}
      </form>
    </ApplyModalContent>
  )
}

export default ApplyUnauthorizedForm
