import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { useResumeApplyContext } from "src/context/ResumeApplyProvider"
import { ApplySteps } from "src/context/ResumeApplyProvider.hooks"
import { cn } from "src/utils/cn"
import Cookie from "src/utils/configs/Cookie"
import { useNaturalPersonQuery } from "../../../query-library/queries/naturalPersonQueries"
import { ResumeApplySchema } from "../../../types/schema/resumeApplyShcema"
import IconButton from "../common/Button/IconButton"
import ApplyModalWrapper from "./modal/ApplyModalWrapper"
import ResumeConfirmationStep from "./steps/DeprecatedResumeConfirmationStep"
import ResumeStepGuestForm from "./steps/ResumeStepGuestForm"
import ResumeStepLoginOrApplyForm from "./steps/ResumeStepLoginOrApplyForm"
import ResumeStepOtpForm from "./steps/ResumeStepOtpForm"
import ResumeSuccessStep from "./steps/ResumeSuccessStep"

const ResumeApplyContainer = () => {
  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(ResumeApplySchema())
  })
  const { resumeModalStep, vacancy_id, setResumeModalStep, closeAndResetModal } = useResumeApplyContext()
  const naturalPersonQuery = useNaturalPersonQuery()

  useEffect(() => {
    if (Cookie.isAuthenticated && naturalPersonQuery.data) {
      setResumeModalStep(ApplySteps.SIGN_IN_OR_MANUAL_SEND)
    }
  }, [Cookie.isAuthenticated, naturalPersonQuery.data])

  const isOpen = resumeModalStep !== null && vacancy_id !== null

  const onClose = () => {
    methods.clearErrors()
    closeAndResetModal()
    methods.reset()
  }

  return (
    <ApplyModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      // className={cx("max-h-[603px] max-w-[560px]", !resumeModalStep && "!h-0", true && "h-full")}
      className={cn(
        "max-h-full md:max-h-[660px] max-w-[560px] h-full",
        !resumeModalStep && "!h-0",
        resumeModalStep === ApplySteps.GUEST_FORM && "md:max-h-[700px]",
        resumeModalStep === ApplySteps.SIGN_IN_OR_MANUAL_SEND && "md:max-h-[700px]"
      )}>
      <div className="absolute right-4 top-4 opacity-70 hover:opacity-100">
        <IconButton src="/images/close.svg" onClick={onClose} />
      </div>
      {resumeModalStep === ApplySteps.SIGN_IN_OR_MANUAL_SEND && <ResumeStepLoginOrApplyForm />}

      {/* Unauthenticated/Unauthorized Steps */}
      <FormProvider {...methods}>
        {resumeModalStep === ApplySteps.GUEST_FORM && <ResumeStepGuestForm />}
        {resumeModalStep === ApplySteps.OTP_FORM && <ResumeStepOtpForm />}
      </FormProvider>

      {/* Successs */}
      {resumeModalStep === ApplySteps.SUCCESS && <ResumeSuccessStep />}

      {/* I think we don't use this anymore */}
      {/* SETP 5 is AUTHORIZED USER. HE SKIPS ABOVE STEPS,  */}
      {resumeModalStep === ApplySteps.CONFIRMATION && <ResumeConfirmationStep vacancy_id={vacancy_id!} />}

      {/* Step 4 content */}
      {/* <ModalStep isOpen={step === 4} onClose={closeAndResetModal}>
            <div className='flex flex-col items-center gap-14'>
              <Image src='/images/mail_success.svg' width={360} height={290} alt='success mail' />

              <Typography type='h3'>რეზიუმე წარმატებით გაიგზავნა</Typography>

              <Button intent='primary' onClick={closeAndResetModal} text='დახურვა' className='w-full' />
            </div>
          </ModalStep> */}
    </ApplyModalWrapper>
  )
}

export default ResumeApplyContainer
