import React, { FC } from "react"
import Typography from "src/views/components/typography"
import { Dialog } from "@headlessui/react"
import Image from "next/image"
import { cx } from "class-variance-authority"

interface Props {
  title: string
  onClose: any
  classes?: string
}
const ProfileModalTitle: FC<Props> = ({ title, onClose, classes }) => {
  return (
    <Dialog.Title className={cx(`sticky top-0 flex items-center justify-between bg-white ${classes}`, "z-0 mb-0")}>
      <Typography type="h4" weight="bold" className="hidden md:flex">
        {title}
      </Typography>
      <Typography type="text" weight="medium" className="md:hidden">
        {title}
      </Typography>
      <div className="cursor-pointer" onClick={onClose}>
        <Image src="/images/close.svg" width={20} height={20} alt="close" />
      </div>
    </Dialog.Title>
  )
}

export default ProfileModalTitle
