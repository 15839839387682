import { useMutation } from "@tanstack/react-query"
import Image from "next/image"
import { usePathname } from "next/navigation"
import { useRouter } from "next/router"
import React, { useContext, useState } from "react"
import { useFormContext } from "react-hook-form"
import toast from "react-hot-toast"
import Button from "src/views/components/common/Button/Button"
import Typography from "src/views/components/typography"
import { ResumeApplyContext, useResumeApplyContext } from "src/context/ResumeApplyProvider"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { postSendOTP } from "../../../../server/queryHooks/useGlobal"
import useVacancy from "../../../../server/queryHooks/useVacancy"
import ApplyModalContent from "../modal/ApplyModalContent"
import OtpInput from "../OtpInput"
import { useTranslation } from "next-i18next"
import { NODE_ENV } from "src/env"
import { ApplySteps } from "src/context/ResumeApplyProvider.hooks"
import useApplyMutations from "src/query-library/mutations/useApplyMutations"

// Send request values
type FormData = {
  name: string
  mobile: string
  profession: string
  email: string
  cv: any
  code: string
}

/* 
  {
    vacancy_id
    name
    mobile
    email
    profession
    cv
    code
  }
  */

// step 3
const ResumeStepOtpForm = () => {
  const { t } = useTranslation("common")
  const { vacancyGuestApplyMutation } = useApplyMutations()
  const { vacancy_id, setResumeModalStep } = useResumeApplyContext()
  const [serverErrMsg, setServerErrMsg] = useState<string | undefined>()
  const [counter, setCounter] = useState(0)
  const [otp, setOtp] = useState(new Array(4).fill(""))
  const [invalidCoreError, setInvalidCoreError] = useState(false)

  React.useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
      setServerErrMsg(`${t("CODE_RESEND_IS_AVAILABLE")} ${counter} ${t("IN_SECOND")}`)
    } else {
      setServerErrMsg("")
    }
  }, [counter])

  const {
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors }
  } = useFormContext<FormData>()

  const pathname = usePathname()
  const router = useRouter()
  const mobile = watch("mobile")
  const { appliedVacanciesQuery: vacancyApplicationsQuery } = useVacancy()

  if (!mobile) {
    router.push(pathname)
  }

  const postSendOTPMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_SEND_OTP],
    mutationFn: postSendOTP,
    onError: (err, _, context) => {
      if (err instanceof Error) {
        const error: any = err

        if (error?.response?.data?.message?.error_data?.phone[0] === "invalid_format") {
          setError("mobile", { type: "custom", message: t("WRONG_MOBILE_FORMAT") })
        }
        if (error?.response?.data?.message?.error_data?.phone[0] === "timeout") {
          setCounter(error?.response?.data?.message?.error_data?.timeout[0])
          setServerErrMsg(`${t("CODE_RESEND_IS_AVAILABLE")} ${error?.response?.data?.message?.error_data?.timeout[0]} ${t("IN_SECOND")}`)
        }
      }
    },
    onSuccess: () => {
      toast.success(t("CODE_SENT_SUCESSFULLY"))
      // Move to last step of modal
      // moveToApplySuccessPanel()
    }
  })

  const onSubmit = (data: { email: string; mobile: string; name: string; profession: string; cv: File }) => {
    const code = otp.join("")

    if (code.length === 4) {
      vacancyGuestApplyMutation.mutate(
        {
          ...data,
          vacancy_id: `${vacancy_id}`,
          code: code
        },
        {
          onSuccess: () => {
            setResumeModalStep(ApplySteps.SUCCESS)
            reset()
            vacancyApplicationsQuery.refetch()
            if (NODE_ENV === "production") {
              window.gtag("event", "cv_send_guest", {
                currency: "USD",
                value: 0
              })
            }
          },
          onError: (error: any) => {
            if (error) {
              if (error?.response?.data?.message === "Invalid verification code.") {
                setInvalidCoreError(true)
              }
            }
          }
        }
      )
    }
  }

  return (
    <ApplyModalContent showBackButton title={t("SEND_RESUME")} subtitle={`${t("CODE_IS_SENT_ON_NUMBER")} ${mobile}`}>
      <div className="mx-auto my-10">
        <Image src="/images/phone_cover.svg" width={180} height={196} alt="otp phone" className="" />
      </div>

      {/* OTP INPUT */}
      <OtpInput otp={otp} setOtp={setOtp} invalidCoreError={invalidCoreError} />
      {/* OTP INPUT */}
      <div className="mt-auto">
        {!serverErrMsg ? (
          <div>
            <Button
              intent="text"
              text={t("RESEND")}
              size={"none"}
              className="text-secondary-100"
              disabled={postSendOTPMutation.isLoading || counter > 0}
              onClick={() => postSendOTPMutation.mutate({ phone: Number(mobile) })}
            />
          </div>
        ) : (
          <Typography className="text-error-100" type="small">
            {serverErrMsg}
          </Typography>
        )}
        <div className="mt-6">
          <div className="flex items-center gap-4">
            <Button
              intent="primary"
              text={t("APPROVE")}
              disabled={otp.some(o => o === "")}
              loading={vacancyGuestApplyMutation.isLoading}
              className=" w-full"
              weight={"regular"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </ApplyModalContent>
  )
}

export default ResumeStepOtpForm
