import NaturalPersonService from "src/server/services/NaturalPersonService"
import UserService from "src/server/services/UserService"

export const fetchNaturalPerson = async (req?: any) => {
  try {
    const response: any = await UserService.getNaturalPerson(req)

    if (response.data.message === "not registered") {
      throw new Error("User is not Authorized, No Profile")
    }

    const registrationData = response.data.data

    return registrationData
  } catch (error: any) {
    throw new Error("Failed to fetch fetchNaturalPerson", error)
  }
}
function withFormData(data: Object) {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value)
  })
  return formData
}
export function withMobile(mobile: string) {
  let mobileCopy = mobile
  if (mobileCopy.startsWith("995")) {
    mobileCopy = "+" + mobileCopy
  } else if (!mobileCopy.startsWith("+995")) {
    mobileCopy = "+995" + mobileCopy
  }
  return mobileCopy
}

export enum ApplyErrorsResponse {
  ALREADY_APPLIED = "You have already applied for this vacancy.",
  INDUSTRY_ERROR_TOAST = "გთხოვთ აირჩიოთ ინდუსტრია"
}
export const postNaturalPersonVacancyApplicant = async (data: any) => {
  try {
    const response: any = await NaturalPersonService.postNaturalPersonVacancyApplicant(withFormData(data))

    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message === ApplyErrorsResponse.ALREADY_APPLIED) {
      throw new Error(ApplyErrorsResponse.ALREADY_APPLIED)
    }
    if (error?.response?.data?.errors?.[0]?.attribute === "industry_id") {
      throw new Error(ApplyErrorsResponse.INDUSTRY_ERROR_TOAST)
    }

    // Is this unreachable?
    throw new Error(error)
  }
}

export const postAuthorizedNaturalPersonVacancyApplicant = async (data: any) => {
  try {
    const response: any = await NaturalPersonService.postNaturalPersonVacancyApplicant(withFormData(data))

    return response.data
  } catch (error: any) {
    throw new Error("Failed posting postNaturalPersonVacancyApplicant", error)
  }
}

export const deleteNaturalPerson = async () => {
  try {
    const response: any = await NaturalPersonService.deleteNaturalPerson()

    return response.data
  } catch (error: any) {
    throw new Error("Failed deleting Natural Person", error)
  }
}
