// components/ApplyModalWrapper.js
import { Dialog } from "@headlessui/react"
import Typography from "src/views/components/typography"
import { useResumeApplyContext } from "src/context/ResumeApplyProvider"
import IconButton from "src/views/components/common/Button/IconButton"
import { cx } from "class-variance-authority"

interface Props {
  children: React.ReactNode
  title?: string
  subtitle?: string
  showBackButton?: boolean
  textLeft?: boolean
}
// This is a children of ApplyModalWrapper
const ApplyModalContent = ({ subtitle, textLeft = false, children, title, showBackButton }: Props) => {
  const { prevStep, setResumeModalStep } = useResumeApplyContext()

  return (
    <>
      {showBackButton && prevStep && (
        <div className="absolute left-4 top-4 opacity-70 hover:opacity-100">
          <IconButton src="/images/arrow_back.svg" onClick={() => setResumeModalStep(prevStep)} />
        </div>
      )}

      <Dialog.Title as="div">
        <Typography type="h4" className={cx(textLeft && "text-left")}>
          {title}
        </Typography>
      </Dialog.Title>

      <Typography type="body" className="mt-2 md:mt-3 px-8" color="light" weight="medium">
        {subtitle}
      </Typography>
      <div className="z-10 flex flex-1 flex-col overflow-auto h-full">{children}</div>
    </>
  )
}

export default ApplyModalContent
