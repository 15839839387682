import { useMutation } from "@tanstack/react-query"
import { ApplyErrorsResponse, deleteNaturalPerson, postNaturalPersonVacancyApplicant } from "../fetchers/naturalPersonFetchers"
import { UnauthorizedSchema } from "./../../views/components/ResumeApply/CreateUnauthorizedUser/schema"
import toast from "react-hot-toast"
import { AxiosError } from "axios"

interface ApplyPayload extends UnauthorizedSchema {
  vacancy_id: number
  update_cv?: 1
}
export const useUnauthorizedNaturalPersonApplyMutation = () => {
  return useMutation({
    mutationFn: (data: ApplyPayload) => postNaturalPersonVacancyApplicant(data),
    mutationKey: ["postNaturalPersonVacancyApplicant"],
    onError: (error: AxiosError) => {
      if (error?.message === ApplyErrorsResponse.ALREADY_APPLIED) {
        toast.error("You have already applied for this vacancy.")
      } else if (error?.message === ApplyErrorsResponse.INDUSTRY_ERROR_TOAST) {
        toast.error(ApplyErrorsResponse.INDUSTRY_ERROR_TOAST)
      } else {
        toast.error("ERROR")
      }
    }
  })
}

export const useAuthorizedNaturalPersonVacancyApplyMutation = () => {
  return useMutation({
    mutationFn: (data: ApplyPayload) => postNaturalPersonVacancyApplicant(data),
    mutationKey: ["postNaturalPersonVacancyApplicant"]
  })
}

export const useNaturalPersonDeleteMutation = () => {
  return useMutation({
    mutationFn: () => deleteNaturalPerson(),
    mutationKey: ["deleteNaturalPerson"]
  })
}
