import React, { createContext, useContext } from "react"
import ResumeApplyContainer from "src/views/components/ResumeApply/ResumeApplyContainer"
import { ApplySteps, ResumeApplyState, useResumeApplyState } from "./ResumeApplyProvider.hooks"

interface ResumeApplyContextValue extends ResumeApplyState {
  setResumeModalStep: (string: ApplySteps) => void
  vacancy_id: number | null
  setVacancyId: (num: any) => any
  closeAndResetModal: any
}

export const ResumeApplyContext = createContext<ResumeApplyContextValue | undefined>(undefined)

const ResumeApplyProvider = ({ children }: any) => {
  const { state, setVacancyId, setResumeModalStep, closeAndResetModal } = useResumeApplyState()

  // if (!state.isClient) return null
  return (
    <ResumeApplyContext.Provider
      value={{
        ...state,
        setVacancyId,
        setResumeModalStep,
        closeAndResetModal
      }}>
      <>
        <ResumeApplyContainer />
        {children}
      </>
    </ResumeApplyContext.Provider>
  )
}

export const useResumeApplyContext = () => {
  const context = useContext(ResumeApplyContext)
  if (!context) {
    throw new Error("useResumeApplyContext must be used within a ResumeApplyProvider")
  }

  return context
}

export default ResumeApplyProvider
