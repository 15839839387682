import React from "react"
import { useTranslation } from "next-i18next"
import { useMainPageV3Query } from "../../../../query-library/queries/publicQueries"
import { ICategoryMini } from "../../../../types/commonTypes"
import ControlledCombobox from "./ControlledCombobox"

const IndustrySelect = ({ control, defaultValue }: { control: any; defaultValue?: number | undefined }) => {
  const { t } = useTranslation("common")
  const mainPageV3Query = useMainPageV3Query()
  const categories = mainPageV3Query.data?.categories || []
  const mapCategories = (category: ICategoryMini) => ({ id: category.id, label: category.title, value: category.id })
  if (!categories) {
    return null
  }
  return (
    <ControlledCombobox
      loading={mainPageV3Query.isLoading}
      options={[...categories.map(mapCategories), { id: 10000, label: t("OTHER"), value: 10000 }]}
      control={control}
      label={t("INDUSTRY")}
      name="industry_id"
      defaultValue={defaultValue}
    />
  )
}

export default IndustrySelect
