type FallbackProps = {
  error: string
  resetErrorBoundary: () => void
}
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div className="p-4 border-2 border-red-500 bg-neutral-900 rounded-lg">
      <h2 className="text-lg font-semibold text-red-700">Something went wrong:</h2>
      {/* <div className="my-2 text-sm text-red-600">{error}</div> */}
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default ErrorFallback
