import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { INaturalPersonInterface } from "src/types/naturalPersonWizardTypes"
import { fetchNaturalPerson } from "../fetchers/naturalPersonFetchers"
import Cookie from "src/utils/configs/Cookie"
import { getSavedAppliedSubscribedStatus } from "src/server/queryHooks/useVacancy"

export const useNaturalPersonQuery = () => {
  return useQuery<INaturalPersonInterface>({
    queryKey: [QUERY_KEYS.GET_NATURAL_PERSON_DATA],
    queryFn: fetchNaturalPerson,
    enabled: !!Cookie.isAuthenticated
  })
}

export interface ISavedAppliedSubscribedStatus {
  saved: Record<number, boolean>
  applied: Record<number, boolean>
  subscribed: Record<number, boolean>
}
export const useSavedAppliedSubscribedCountQuery = () =>
  useQuery<ISavedAppliedSubscribedStatus>({
    queryKey: [QUERY_KEYS.GET_SAVED_APPLIED_STATUS],
    queryFn: getSavedAppliedSubscribedStatus,
    enabled: Cookie.isAuthenticated,
    staleTime: Infinity,
    placeholderData: { saved: {}, applied: {}, subscribed: {} }
  })

export const usePersonSavedAppliedSubscribedStatus = () =>
  useQuery<{
    saved: Record<number, boolean>
    applied: Record<number, boolean>
  }>({
    queryKey: [QUERY_KEYS.GET_SAVED_APPLIED_STATUS],
    queryFn: getSavedAppliedSubscribedStatus,
    enabled: Cookie.isAuthenticated,
    staleTime: Infinity,
    placeholderData: { saved: {}, applied: {} }
  })
