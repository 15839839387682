import HttpService from "./HttpService"

class MainService extends HttpService {
  getLanguagesCountries() {
    return this.get("/public/languages-countries")
  }

  getCategoriesSubcategories() {
    return this.get("/public/categories")
  }
}

export default new MainService()
